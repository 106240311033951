
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import { addDoc, collection, getFirestore } from "firebase/firestore/lite";
import { firebaseApp } from "@/firebase";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import Thumb from "@/common/Thumb.vue";

export default defineComponent({
  name: "ContactUs",
  components: { Thumb },
  data() {
    return {
      page: undefined as StaticContent | undefined,

      name: "" as string,
      email: "" as string,
      phone: "" as string,
      message: "" as string,
      agree: 0 as number,

      isFormSending: false,
      isFormSent: false,
      isFormSentError: false,

      isValid: true,
      isValidName: true,
      isValidEmail: true,
      isValidMessage: true,
      isValidAgree: true,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    email() {
      this.isValidEmail = !!this.email.trim() && this.checkEmail(this.email);
      this.checkValidation();
    },
    name() {
      this.isValidName = !!this.name.trim();
      this.checkValidation();
    },
    message() {
      this.isValidMessage = !!this.message.trim();
      this.checkValidation();
    },
    agree() {
      this.isValidAgree = !!this.agree;
      this.checkValidation();
    },
  },

  methods: {
    loadData() {
      contentsService.findBySlug("contact-us").then((page) => {
        this.page = page;
        setPageTitle(page?.title);
        setPageDescription(page?.lead);
      });
    },
    checkValidation() {
      this.isValid =
        this.isValidName &&
        this.isValidMessage &&
        this.isValidEmail &&
        this.isValidAgree;
    },
    send() {
      this.isValidEmail = !!this.email.trim() && this.checkEmail(this.email);
      this.isValidName = !!this.name.trim();
      this.isValidMessage = !!this.message.trim();
      this.isValidAgree = !!this.agree;
      this.checkValidation();
      if (this.isValid) {
        this.sendEmail();
      }
    },
    sendEmail() {
      this.isFormSending = true;
      const db = getFirestore(firebaseApp);
      const citiesCol = collection(db, "mails");
      addDoc(citiesCol, {
        to: "hello@engenious.pl",
        replayTo: this.email,
        message: {
          subject: "[EngeniousPl]: " + this.name,
          text:
            this.message +
            "\n\n--\n" +
            this.name +
            (this.phone ? "\ntel: " + this.phone : "") +
            "\nemail: " +
            this.email,
          html:
            this.message.replace(/\n/g, "<br />") +
            "<br><br>--<br>" +
            this.name +
            (this.phone ? "<br>tel: " + this.phone : "") +
            "<br>email: " +
            this.email,
        },
      })
        .then(() => {
          this.isFormSent = true;
          this.isFormSending = false;
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          console.error(err);
          this.isFormSending = false;
          window.scrollTo(0, 0);
        });
    },
    checkEmail(email: string) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
});
